import { createApi } from "@reduxjs/toolkit/query/react";
import { UserData, UserFields, Roles } from "types/users";
import { Filter } from "types/filters";
import { baseAppQuery } from "./baseQuery";

const ENDPOINT = "/employee";
const employeesApi: any = createApi({
  reducerPath: "employeesApi",
  baseQuery: baseAppQuery,
  keepUnusedDataFor: 0,
  tagTypes: ["employees"],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getEmployeeList: builder.query<UserData, Filter & { organizationId?: string }>({
      query: (filter) => {
        const queryParams = new URLSearchParams();
        if (filter) {
          if (filter.pageNumber) queryParams.set("page", filter.pageNumber.toString());
          if (filter.pageSize) queryParams.set("size", filter.pageSize.toString());
          if (filter.organizationId) queryParams.set("organizationId", filter.organizationId);
        }
        return `${ENDPOINT}/fetch-all?${queryParams}`;
      },
      providesTags: ["employees"],
    }),
    getEmployeeListCurrent: builder.query<UserData, Filter>({
      query: (filter) => {
        const queryParams = new URLSearchParams();
        if (filter) {
          queryParams.set("page", filter.pageNumber.toString());
          queryParams.set("size", filter.pageSize.toString());
        }
        return `${ENDPOINT}/fetch-all-current?${queryParams}`;
      },
      providesTags: ["employees"],
    }),
    getEmployeeById: builder.query<UserData, string>({
      query: (id) => `${ENDPOINT}/${id}`,
    }),
    getEmployeeRoleList: builder.query<Roles, unknown>({
      query: () => `${ENDPOINT}/role/list`,
    }),
    addEmployee: builder.mutation<UserData, UserFields>({
      query: (data) => ({
        url: ENDPOINT,
        method: "POST",
        body: data,
      }),
    }),
    lockEmployee: builder.mutation<UserData, { id: string; isBlock: string }>({
      query: ({ id, isBlock }) => ({
        url: `${ENDPOINT}/lock/${id}?isBlock=${isBlock}`,
        method: "POST",
      }),
      invalidatesTags: ["employees"],
    }),
    updateEmployee: builder.mutation<UserData, UserFields>({
      query: (data) => ({
        url: ENDPOINT,
        method: "PUT",
        body: data,
      }),
    }),
    deleteEmployee: builder.mutation<UserData, string>({
      query: (id) => ({
        url: `${ENDPOINT}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["employees"],
    }),
    setSigner: builder.mutation<undefined, string>({
      query: (id) => ({
        url: `${ENDPOINT}/set-signer?id=${id}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetEmployeeListQuery,
  useGetEmployeeListCurrentQuery,
  useGetEmployeeByIdQuery,
  useGetEmployeeRoleListQuery,
  useAddEmployeeMutation,
  useLockEmployeeMutation,
  useUpdateEmployeeMutation,
  useDeleteEmployeeMutation,
  useSetSignerMutation,
} = employeesApi;
export default employeesApi;
