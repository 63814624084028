import { createApi } from "@reduxjs/toolkit/query/react";
import {
  ApplicationData,
  ApplicationInfo,
  ApplicationFields,
  ApplicationFiles,
} from "types/application";
import { StatusHistory } from "types/statuses";
import { Filter } from "types/filters";
import { UploadedFile } from "types/dropzone";
import { baseAppQuery } from "./baseQuery";

const ENDPOINT = "/application";
const applicationApi: any = createApi({
  reducerPath: "applicationApi",
  baseQuery: baseAppQuery,
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: true,
  tagTypes: ["application", "applicationFile"],
  endpoints: (builder) => ({
    getApplicationList: builder.query<ApplicationData, Filter>({
      query: (filter) => {
        const queryParams = new URLSearchParams();
        if (filter) {
          queryParams.set("page", filter.pageNumber.toString());
          queryParams.set("size", filter.pageSize.toString());

          if (filter.orgId) {
            queryParams.set("orgId", filter.orgId);
          }
        }

        return `${ENDPOINT}/fetch-all?${queryParams}`;
      },
    }),
    getApplicationById: builder.query<ApplicationData, string>({
      query: (id) => `${ENDPOINT}/${id}`,
      providesTags: ["application", "applicationFile"],
    }),
    getApplicationListCurrent: builder.query<ApplicationData, Filter>({
      query: (filter) => {
        const queryParams = new URLSearchParams();
        if (filter) {
          queryParams.set("page", filter.pageNumber.toString());
          queryParams.set("size", filter.pageSize.toString());
        }
        return `${ENDPOINT}/fetch-all-current?${queryParams}`;
      },
      providesTags: ["application"],
    }),
    getApplicationByIdCurrent: builder.query<ApplicationData, string>({
      query: (id) => `${ENDPOINT}/current/${id}`,
      providesTags: ["application", "applicationFile"],
    }),
    getApplicationHistoryById: builder.query<StatusHistory[], string>({
      query: (id) => `${ENDPOINT}/status-history/${id}`,
      providesTags: ["application"],
    }),
    getApplicationDocumentListById: builder.query<ApplicationFiles[], string>({
      query: (id) => `${ENDPOINT}/document/list/${id}`,
      providesTags: ["applicationFile"],
    }),
    getApplicationAvailableActions: builder.query<string[], string>({
      query: (id) => `${ENDPOINT}/available-actions/${id}`,
      providesTags: ["application", "applicationFile"],
    }),
    getApplicationAvailableActionsBase: builder.query<string[], string>({
      query: (id) => `${ENDPOINT}/available-actions-base/${id}`,
    }),
    createApplication: builder.mutation<ApplicationData, string>({
      query: () => ({
        url: ENDPOINT,
        method: "POST",
      }),
    }),
    rejectApplication: builder.mutation<
      ApplicationData,
      { applicationId: string; comment: string }
    >({
      query: (data) => ({
        url: `${ENDPOINT}/reject`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["application"],
    }),
    revisionApplication: builder.mutation<
      ApplicationData,
      { applicationId: string; comment: string }
    >({
      query: (data) => ({
        url: `${ENDPOINT}/revision`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["application"],
    }),
    updateApplication: builder.mutation<ApplicationData, ApplicationFields>({
      query: (data) => ({
        url: ENDPOINT,
        method: "PUT",
        body: data,
      }),
    }),
    deleteApplication: builder.mutation<ApplicationData, string>({
      query: (id) => ({
        url: `${ENDPOINT}/${id}`,
        method: "DELETE",
      }),
    }),
    downloadApplicationFiles: builder.query<UploadedFile, string>({
      query: (documentId) => ({
        url: `${ENDPOINT}/download/${documentId}`,
        responseHandler: (response) => response.blob().then((blob) => blob),
      }),
    }),
    uploadApplicationFiles: builder.mutation<
      ApplicationInfo,
      { applicationId: string; documentTypeId: string; files: File }
    >({
      query: ({ applicationId, documentTypeId, files }) => {
        let formData = new FormData();
        formData.append("files", files);
        return {
          url: `${ENDPOINT}/document/upload?applicationId=${applicationId}&documentTypeId=${documentTypeId}`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["applicationFile"],
    }),
    deleteApplicationFiles: builder.mutation<
      File,
      { applicationId: string; documentId: string }
    >({
      query: ({ applicationId, documentId }) => ({
        url: `${ENDPOINT}/document/delete?applicationId=${applicationId}&documentId=${documentId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["applicationFile"],
    }),
    deleteApplicationFileByCurrent: builder.mutation<
      File,
      { applicationId: string; documentId: string }
    >({
      query: ({ applicationId, documentId }) => ({
        url: `${ENDPOINT}/document/delete-by-current?applicationId=${applicationId}&documentId=${documentId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetApplicationListQuery,
  useGetApplicationByIdQuery,
  useGetApplicationListCurrentQuery,
  useGetApplicationByIdCurrentQuery,
  useGetApplicationHistoryByIdQuery,
  useGetApplicationDocumentListByIdQuery,
  useGetApplicationAvailableActionsQuery,
  useGetApplicationAvailableActionsBaseQuery,
  useCreateApplicationMutation,
  useRejectApplicationMutation,
  useRevisionApplicationMutation,
  useUpdateApplicationMutation,
  useDeleteApplicationMutation,
  useDownloadApplicationFilesQuery,
  useLazyDownloadApplicationFilesQuery,
  useUploadApplicationFilesMutation,
  useDeleteApplicationFilesMutation,
} = applicationApi;
export default applicationApi;
