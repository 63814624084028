import { createApi } from "@reduxjs/toolkit/query/react";
import { OrganizationData, OrganizationFields, BankAccount, BankAccountFields } from "types/organization";
import { Filter } from "types/filters";
import { baseAppQuery } from "./baseQuery";

const ENDPOINT = "/organization";
const organizationsApi: any = createApi({
  reducerPath: "organizationsApi",
  baseQuery: baseAppQuery,
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: true,
  tagTypes: ["organizations"],
  endpoints: (builder) => ({
    getOrganizationList: builder.query<OrganizationData, Filter>({
      query: (filter) => {
        const queryParams = new URLSearchParams();
        if (filter) {
          queryParams.set("page", filter.pageNumber.toString());
          queryParams.set("size", filter.pageSize.toString());
        }
        return `${ENDPOINT}/fetch-all?${queryParams}`;
      },
      providesTags: ["organizations"],
    }),
    getOrganizationById: builder.query<OrganizationData, string>({
      query: (id) => `${ENDPOINT}/${id}`,
    }),
    addOrganization: builder.mutation<OrganizationData, OrganizationFields>({
      query: (data) => ({
        url: ENDPOINT,
        method: "POST",
        body: data,
      }),
    }),
    updateOrganization: builder.mutation<OrganizationData, OrganizationFields>({
      query: (data) => ({
        url: ENDPOINT,
        method: "PUT",
        body: data,
      }),
    }),
    getBankAccountList: builder.query<Array<BankAccount>, string>({
      query: (organizationId: string) => `${ENDPOINT}/bank-account/list?clientId=${organizationId}`,
      providesTags: ["organizations"],
    }),
    addBankAccount: builder.mutation<BankAccount, BankAccountFields>({
      query: (data) => ({
        url: `${ENDPOINT}/bank-account`,
        method: "POST",
        body: data,
      }),
    }),
    updateBankAccount: builder.mutation<BankAccount, BankAccountFields>({
      query: (data) => ({
        url: `${ENDPOINT}/bank-account`,
        method: "PUT",
        body: data,
      }),
    }),
    deleteBankAccount: builder.mutation<undefined, string>({
      query: (id) => ({
        url: `${ENDPOINT}/bank-account?id=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["organizations"],
    }),
    activateBankAccount: builder.mutation<undefined, { id: string; orgId: string }>({
      query: ({ id, orgId }) => ({
        url: `${ENDPOINT}/bank-account/main?bankAccountId=${id}&clientId=${orgId}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetOrganizationListQuery,
  useGetOrganizationByIdQuery,
  useAddOrganizationMutation,
  useUpdateOrganizationMutation,
  useGetBankAccountListQuery,
  useAddBankAccountMutation,
  useUpdateBankAccountMutation,
  useDeleteBankAccountMutation,
  useActivateBankAccountMutation,
} = organizationsApi;
export default organizationsApi;
