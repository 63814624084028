import { PaginationData, Date } from "./filters";
import { Dictionary } from "./dictionary";
import { Dayjs } from "dayjs";

export enum ERoles {
  admin = "ADMIN",
  director = "DIRECTOR",
  deputy_director = "DEPUTY_DIRECTOR",
  risk_manager = "RISK_MANAGER",
  lawyer = "LAWYER",
  credit_manager = "CREDIT_MANAGER",
  client = "CLIENT",
}

export type Roles = Omit<Dictionary, "description">;

export type Creator = {
  firstName: string;
  lastName: string;
  iin: string;
};

type Users = {
  id: string;
  userId?: string;
  createTimestamp: string;
  updateTimestamp: string;
};

export type UserFields = {
  photo?: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  email?: string;
  phone?: string;
  address?: string;
  iin?: string;
  bin?: string;
  organization?: string;
  status?: string;
  userRole?: ERoles;
  createdBy?: Creator;
  additionalInfo?: string;
  isSigner?: boolean;
  organizationId?: string;
  position?: string;
  genitiveName?: string;
  clientBasisForDirector?: string;
};

export type ClientFields = UserFields & {
  orgName: string;
  orgId: string;
  organizationType: string;
  ndsCertificate?: string;
};

export type ClientInfo = Users & ClientFields;
export type UserInfo = Users & UserFields;

export type ClientData = PaginationData & {
  content: ClientInfo[];
};
export type UserData = PaginationData & {
  content: UserInfo[];
};

export type ClientFilter = {
  firstName: string;
  lastName: string;
  iin: string;
  orgId: string;
  status: string;
  date: Date;
};
export type EmployeeFilter = {
  firstName: string;
  lastName: string;
  iin: string;
  bin: string;
  role: string;
  status: string;
};
export type LeadFilter = {
  firstName: string;
  lastName: string;
  status: string;
  dateFrom: string | Dayjs;
  dateTo: string | Dayjs;
};

export type BankAccount = {
  id: string;
  bankBik: string;
  bankIik: string;
  bankName: string;
  isMain: boolean;
};

export type BankAccountFields = {
  id?: string;
  bankBik: string;
  bankIik: string;
  bankName: string;
  isMain: boolean;
  clientId?: string;
};
