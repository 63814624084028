import JSZip from "jszip";
import { ERoles } from "types/users";
import { StatusTitles } from "types/statuses";
import { getUserData } from "utils/getUserData";
import { UploadedFile } from "types/dropzone";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes";
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const fixBase64String = (b64String: string) => {
  if (!b64String) return undefined;

  b64String = b64String.replace(/[^A-Za-z0-9+/=]/g, "");
  while (b64String.length % 4 !== 0) {
    b64String += "=";
  }
  return b64String;
};

export const userFullNameInitials = (
  firstName: string,
  lastName: string,
  middleName?: string,
  short?: boolean
) => {
  const initArrToShorten = [(firstName || "").substring(0, 1)];
  if (!short) initArrToShorten.push((middleName || "").substring(0, 1));
  const initArr = initArrToShorten.filter(
    (a) => a != null && a.trim().length > 0
  );
  const initials = initArr.length === 0 ? "" : `${initArr.join(".")}.`;
  const fnArr = [lastName, initials].filter(
    (a) => a != null && a.trim().length > 0
  );
  if (fnArr.length === 0) return "";
  return fnArr.join(" ");
};

export const userFullName = (
  firstName: string,
  lastName: string,
  middleName?: string,
  short?: boolean
) => {
  const fnArrToShorten = [lastName, firstName];
  if (!short) fnArrToShorten.push(middleName || "");
  const fnArr = fnArrToShorten.filter((a) => a != null && a.trim().length > 0);
  if (fnArr.length === 0) return "";
  return fnArr.join(" ");
};

export const userAvatar = (
  firstName: string,
  lastName: string,
  middleName?: string
) => {
  const initArr = [
    (lastName || "").substring(0, 1),
    (firstName || "").substring(0, 1),
    (middleName || "").substring(0, 1),
  ].filter((a) => a != null && a.trim().length > 0);
  if (initArr.length === 0) return "";
  return initArr.slice(0, 2).join(" ");
};

export const whatsAppLinkNums = (phone: string) => {
  const formatPhoneNums = phone.replace(/[+()\s-]/g, "");
  return `https://api.whatsapp.com/send/?phone=${formatPhoneNums}`;
};

export const emailLink = (email: string) => {
  return `mailto:${email}`;
};

export const zipFiles = async (
  filename: string,
  uploadedFiles: UploadedFile[],
  files: { [key: string]: any }[]
) => {
  const zip = new JSZip();
  const folder = zip.folder(filename);

  files.forEach(({ data: blob, originalArgs: fileId }) => {
    const name = uploadedFiles.find((file) => file.id === fileId)?.fileName;
    if (name) folder?.file(name, blob);
  });
  const zippedContent = await zip.generateAsync({ type: "blob" });
  return URL.createObjectURL(zippedContent);
};

export const downloadFile = (filename: string, url: string) => {
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = filename;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

export const formatBinIinUi = (value: string) => {
  if (!value) return undefined;
  const valList = value.split("");
  for (let i = 3; i <= 11; i += 4) {
    valList.splice(i, 0, " ");
  }
  return valList.join("");
};

export const formatDateUi = (date: string, lang: string) => {
  if (!date) return dayjs().locale(lang).format("D MMMM YYYY [г.]");
  return dayjs(date).locale(lang).format("D MMMM YYYY [г.]");
};

export const getStatusTitleByRole = (
  status: string,
  clientStatus: StatusTitles,
  employeeTitle: StatusTitles
) => {
  const userData = getUserData();
  if (!userData) return "";
  const statusByRole =
    userData.role === ERoles.client ? clientStatus : employeeTitle;

  return statusByRole[status];
};
export const sortFilesByLastDate = (files: UploadedFile[]) => {
  const sortedFiles = files.sort((a: UploadedFile, b: UploadedFile) =>
    new Date(b.createTimestamp) < new Date(a.createTimestamp) ? -1 : 1
  );
  return sortedFiles;
};

export const getFormattedDate = (date: Dayjs | null) => {
  return date ? dayjs(date, "DD.MM.YYYY") : dayjs();
};
