import { createApi } from "@reduxjs/toolkit/query/react";
import { baseAppQuery } from "./baseQuery";
import { Filter } from "types/filters";
import { SignatureDocument } from "types/signatures";
import { ContractData, ContractInfo } from "types/contracts";

const ENDPOINT = "contract";
const contractsApi: any = createApi({
  reducerPath: "contractsApi",
  baseQuery: baseAppQuery,
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: true,
  tagTypes: ["contracts"],
  endpoints: (builder) => ({
    getContractList: builder.query<ContractData, Filter>({
      query: (filter) => {
        const queryParams = new URLSearchParams();
        if (filter) {
          queryParams.set("page", filter.pageNumber.toString());
          queryParams.set("size", filter.pageSize.toString());
          if (filter.orgId) {
            queryParams.set("orgId", filter.orgId);
          }
        }
        return `${ENDPOINT}/fetch-all?${queryParams}`;
      },
    }),
    getContractListCurrent: builder.query<ContractData, Filter>({
      query: (filter) => {
        const queryParams = new URLSearchParams();
        if (filter) {
          queryParams.set("page", filter.pageNumber.toString());
          queryParams.set("size", filter.pageSize.toString());
        }
        return `${ENDPOINT}/fetch-all-current?${queryParams}`;
      },
    }),
    getContractDocument: builder.query<SignatureDocument, string>({
      query: (id) => `${ENDPOINT}/document/${id}`,
    }),
    getContractFindByApplicationId: builder.query<ContractInfo, string>({
      query: (id) => `${ENDPOINT}/find-by-application?applicationId=${id}`,
    }),
    revokeContract: builder.mutation<
      ContractData,
      { applicationId: string; comment: string }
    >({
      query: ({ applicationId, comment }) => ({
        url: `${ENDPOINT}/revoke/${applicationId}?comment=${comment}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetContractListQuery,
  useGetContractListCurrentQuery,
  useGetContractDocumentQuery,
  useLazyGetContractDocumentQuery,
  useGetContractFindByApplicationIdQuery,
  useRevokeContractMutation,
} = contractsApi;
export default contractsApi;
